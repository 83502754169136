<template>
  <div v-bind="api.getRootProps()" :class="classNames.root">
    <div
      v-bind="api.getIndicatorProps()"
      :class="classNames.indicator"
      :style="{ height: 'var(--height)', width: 'var(--width)', left: 'var(--left)' }"
    />
    <label
      v-for="opt in fixedItems"
      v-bind="api.getItemProps({ value: opt.value })"
      :key="opt.value"
      :class="classNames.item"
    >
      <slot
        v-bind="{
          labelAttrs: api.getItemTextProps({ value: opt.value }),
          value: opt.value,
          label: opt.label,
          checked: api.getItemState({ value: opt.value }).checked,
        }"
        name="label"
      >
        <span class="pointer-events-none leading-none" v-bind="api.getItemTextProps({ value: opt.value })">
          {{ opt.label }}</span
        >
      </slot>
      <input class="sr-only" v-bind="api.getItemHiddenInputProps({ value: opt.value })" />
    </label>
  </div>
</template>

<script setup lang="ts" generic="T extends UiSegmentedControlItem">
import * as radio from "@zag-js/radio-group"
import { normalizeProps, useMachine } from "@zag-js/vue"
import type { ClassValue } from "clsx"

export interface UiSegmentedControlItem {
  label: string
  value: string | number
  disabled?: boolean
}

interface UiSegmentedControlProp {
  modelValue: T["value"]
  items: T[]
  classes?: Partial<UiSegmentedControlClasses>
  zagId?: string
  disabled?: boolean
}

interface UiSegmentedControlClasses {
  root: ClassValue
  indicator: ClassValue
  item: ClassValue
}

const props = withDefaults(defineProps<UiSegmentedControlProp>(), { zagId: "segmented-control" })
const emit = defineEmits<{ (e: "update:modelValue", value: T["value"]): void }>()
const currInstance = getCurrentInstance()

const fixedItems = computed(() => {
  return props.items.map((item) => ({ ...item, value: String(item.value) }))
})

const [state, send] = useMachine(
  radio.machine({
    id: props.zagId ?? String(currInstance?.uid),
    disabled: props.disabled,
    value: (props.modelValue as string) || fixedItems.value?.[0]?.value,
    onValueChange({ value }) {
      emit("update:modelValue", value)
    },
  }),
  {
    context: computed(() => ({
      id: props.zagId,
      value: (props.modelValue as string) || fixedItems.value?.[0]?.value,
      disabled: props.disabled,
    })),
  }
)

const api = computed(() => radio.connect(state.value, send, normalizeProps))

const classNames = computed(
  (): UiSegmentedControlClasses => ({
    root: cn(
      "segment-container zag-disabled:grayscale zag-disabled:opacity-70 relative inline-flex rounded-full bg-neutral-200 p-0.5 md:w-full",
      props.classes?.root
    ),
    indicator: cn(
      "z-1 b-shadow rounded-full border bg-white transition-all duration-300",
      props.classes?.indicator
    ),
    item: cn([
      "zag-disabled:cursor-not-allowed",
      "zag-radio-checked:font-medium z-3 zag-radio-checked:text-primary px-xs flex min-h-8 min-w-40 cursor-pointer items-center justify-center rounded-full text-center text-sm text-black/50 md:w-1/2",
      props.classes?.item,
    ]),
  })
)
</script>

<style lang="scss">
.b-shadow {
  border: 0.5px solid rgba(0, 0, 0, 0.03);
  box-shadow:
    0px 1px 2px -0.5px rgba(0, 0, 0, 0.05),
    0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.segment-container {
  border: 0.5px solid rgba(0, 0, 0, 0.01);
}
</style>
