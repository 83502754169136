import { type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/vue-query"
import { sortBy } from "lodash-es"

export type QueryOptions<TQueryData> = Partial<
  Exclude<UseQueryOptions<TQueryData, Error>, "queryKey" | "queryFn">
>

export type GetPricingPlans = Awaited<ReturnType<typeof PricingService.getPricingPlans>>
export function useGetPricingPlans(id?: ProdCodesUnion, isNotifyMe?: boolean) {
  return useQuery({
    queryKey: ["PricingService.getPricingPlans", isNotifyMe],
    queryFn: ({ signal }) =>
      PricingService.getPricingPlans({ signal, params: jsonClean({ productId: id! }) }),
    staleTime: 1000 * 60 * 2, // 2 minutes
    placeholderData: keepPreviousData,
    select(data) {
      return data.map((product) => ({
        ...product,
        plans: sortBy(product.plans, (plan) => plan?.displayOrder || 0),
      }))
    },
  })
}
