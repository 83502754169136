import { PlanCode, PlanPeriodType, PricingProductEnum, RoutesNamesList } from "#imports"

import { i18nCommonLocaleMap } from "@finq/app-base/lib/configs"

import { type PricingPlanPricing, PricingPlanTypes, UiPricingPlans } from "@finq/pricing/types"

export type Feature = {
  title: string
  description?: string
}

export type Term = {
  id: string
  agreement?: boolean
  disclaimer: string
  selected?: boolean
}

export type Discount = {
  period: PlanPeriodType
  percentage: number
}

export type LocalePricingProductPlan = {
  id: PricingProductEnum
  title: string
  banner_title: string
  description: string
  header_background: string
  features: Feature[]
  terms: Term[]
  badge?: string
  bill_period?: string
  /**
   * A plan that does not have 'terms', should have a 'screenPath' (e.g 'kyc/INV')
   */
  screenPath?: string
}

export type PricingPlanWithDetails = LocalePricingProductPlan &
  Omit<PricingPlan, "pricing"> & {
    productId: PricingProductEnum
    pricing: PricingPlanPricing
    code: PlanCode
    locale: string
    planCode: FullProductPlanCodes
  }

export type MappedProduct = {
  [key in PricingProductEnum]: {
    id: PricingProductEnum
    path: RoutesNamesList
    discount: Discount
    label: string
    plans: {
      [key in PlanCode]: PricingPlanWithDetails
    }
  }
}

export type PricingSwitchPeriod =
  | typeof PricingPlanTypes.PlanPeriodType.ANNUAL
  | typeof PricingPlanTypes.PlanPeriodType.MONTHLY

export type FullProductPlanCodes = `${PlanCode}-${PricingProductEnum}-${PlanPeriodType}`

// ------------------------- START ------------------------- //
export const currentPeriod = ref<PricingSwitchPeriod>(PricingPlanTypes.PlanPeriodType.MONTHLY)
const [useProvidePricingStore, useInjectPricingStore] = createInjectionState(
  (options?: { productId?: PricingProductEnum }) => {
    const {
      data: pricingData,
      isPending,
      isRefetching,
      isFetching,
      isError,
    } = useGetPricingPlans(options?.productId)

    const { route } = useRouterUtils()
    useI18nUtils({ useScope: "local" })
    const { t: pt } = useI18n({ useScope: "parent" })
    const { tmsafe: $tmsafe } = useI18nUtils({ useScope: "parent" })

    const translationsProducts = computed(() => {
      const products = $tmsafe<MappedProduct>("products")
      const pricingEnumKeys = Object.keys(PricingProductEnum)

      // Sorting the products by the order of the enum
      return Object.fromEntries(
        Object.entries(products).sort((a, b) => {
          return pricingEnumKeys.indexOf(a[0]) - pricingEnumKeys.indexOf(b[0])
        })
      ) as MappedProduct
    })

    const mappedProducts = computed((): MappedProduct => {
      if (isFetching.value || isRefetching.value || isPending.value) return translationsProducts.value

      return pricingData.value?.reduce((acc, { id: productId, plans }) => {
        const translationData = translationsProducts.value[productId]
        return {
          ...acc,
          [productId]: {
            ...translationData,
            id: productId,
            plans: plans.reduce((acc, plan) => {
              // Plan
              const { pricing, ...restProductPlan } = plan

              const planPricing = jsonClean(
                plan.pricing.find((p) => p.type === currentPeriod.value) || plan.pricing[0]
              ) as PricingPlanPricing

              const isEitherAnnualOrMonthly = computed(() => {
                return [
                  PricingPlanTypes.PlanPeriodType.MONTHLY,
                  PricingPlanTypes.PlanPeriodType.ANNUAL,
                ].includes(planPricing.type!)
              })

              const fullProductPlanCode = computed((): FullProductPlanCodes => {
                return `${plan.code}-${productId}-${isEitherAnnualOrMonthly.value ? currentPeriod.value : planPricing.type!}`
              })

              const planLocale = SupportedProductsInHebrewOnly.includes(productId)
                ? i18nCommonLocaleMap["he"].code
                : undefined

              return {
                ...acc,
                [plan.code]: {
                  ...restProductPlan,
                  ...$tmsafe<LocalePricingProductPlan>(`products.${productId}.plans.${plan.code}`),
                  productId,
                  code: plan.code,
                  pricing: planPricing,
                  planCode: fullProductPlanCode.value,
                  locale: planLocale,
                },
              }
            }, {}),
          },
        }
      }, {}) as MappedProduct
    })

    const getProductTabs = computed((): PricingProductTabs[] => {
      if (!mappedProducts.value) return []
      return Object.keys?.(mappedProducts.value!)
        .map((productId) => {
          return {
            id: productId,
            value: productId,
            label: pt(`products.${productId}.label`),
            color: isKey(UiPricingPlans.COLORS, productId) ? UiPricingPlans.COLORS[productId] : null,
          }
        })
        .filter((product) => isKey(UiPricingPlans.COLORS, product.value)) as PricingProductTabs[]
    })

    const isPricingPageLoading = computed(() => {
      return isPending.value || isRefetching.value || isFetching.value
    })

    function getProductById(productId: PricingProductEnum): MappedProduct[PricingProductEnum] {
      return isKey(mappedProducts.value, productId)
        ? mappedProducts.value[productId]
        : ({} as MappedProduct[PricingProductEnum])
    }

    function getRawProductById(productId: PricingProductEnum) {
      return pricingData.value?.find((product) => product.id === productId)
    }

    function getProductPlanById(productId: PricingProductEnum, planCode: PlanCode): PricingPlanWithDetails {
      return getProductById(productId)?.plans?.[planCode]
    }

    function setCurrentPeriod(period: PricingSwitchPeriod) {
      currentPeriod.value = period
    }

    function isProductExist(productId: PricingProductEnum): boolean {
      return isKey(mappedProducts.value, productId)
    }

    return {
      isPricingPageLoading,
      isFailedToLoadPricingData: isError,

      productId: options?.productId,
      currentProductRouteParam: computed(
        () => (route.value.params as { productId: PricingProductEnum }).productId
      ),
      getProductTabs,
      mappedProducts,

      getProductById,
      getProductPlanById,
      getRawProductById,
      isProductExist,

      currentPeriod,
      setCurrentPeriod,
    }
  }
)

function usePricingStore() {
  const pricingState = useInjectPricingStore()

  if (!pricingState) throw new Error("usePricingStore must be used within a PricingManager")

  return pricingState
}

export { useProvidePricingStore, usePricingStore }
