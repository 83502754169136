import { array } from "valibot"

import { BaseApiService, KyOptions } from "@finq/app-base/composables/services/api.service"

import { PricingPlanTypes } from "../../types"

class PricingServiceRaw extends BaseApiService {
  getPricingPlans = async (config: KyOptions = {}) => {
    return this.instance
      .get("products-temp", config)
      .then(this.getData)
      .then((res) => this.parse(res, array(PricingPlanTypes.pricingProductResponseSchema)))
  }
}

/** Exporting a singleton object to entire app */
export const PricingService = new PricingServiceRaw()
